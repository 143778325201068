import React, { ReactElement } from 'react';

import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';
import Breadcrumbs from '@components/pages/blog/Breadcrumbs';

import useTranslation from '@hooks/useTranslation';

import Categories from './Categories';
import styles from './Navigation.module.css';
import SearchBar from './SearchBar';

interface NavProps {
    categories?: Array<{ name: string; href: string }>;
    breadcrumbs?: ReactElement;
}

interface BreadcrumbProps {
    href:
        | string
        | {
              pathname: string;
          };
    isCurrent?: boolean;
}
export const Breadcrumb = ({ href, isCurrent = false, children }: React.PropsWithChildren<BreadcrumbProps>) => {
    if (typeof href === 'string') {
        return (
            <a
                href={href}
                aria-current={isCurrent ? true : undefined}
            >
                {children}
            </a>
        );
    }

    return (
        <CustomLink
            href={href}
            aria-current={isCurrent ? true : undefined}
        >
            {children}
        </CustomLink>
    );
};
export default function Nav({ categories, breadcrumbs }: NavProps): JSX.Element {
    const { t } = useTranslation('pages/blog/navigation');
    const { pathname, query } = useRouter();

    return (
        <div className={styles.nav}>
            <Breadcrumbs>
                {breadcrumbs ? (
                    [
                        <CustomLink
                            key={1}
                            href={{ pathname: '/blog' }}
                            aria-current={pathname === '/blog' && !query.p ? 'page' : undefined}
                        >
                            {t('blog')}
                        </CustomLink>,
                        breadcrumbs.props.children,
                    ]
                ) : (
                    <CustomLink
                        href={{ pathname: '/blog' }}
                        aria-current={pathname === '/blog' && !query.p ? 'page' : undefined}
                    >
                        {t('blog')}
                    </CustomLink>
                )}
            </Breadcrumbs>

            {categories && categories.length > 0 && <Categories tags={categories} />}
            <SearchBar />
        </div>
    );
}
