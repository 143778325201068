import { useId } from 'react';

import getConfig from 'next/config';
import Head from 'next/head';

import CustomLink from '@components/global/CustomLink';

import clsx from 'clsx';

import SinglePostDetails from '../SinglePostDetails';
import styles from './SinglePostBox.module.css';

const { publicRuntimeConfig } = getConfig();

interface SinglePostBoxTagProps {
    href?: string;
    customLink?: JSX.Element;
    title: string;
    nofollow?: boolean;
    featured?: boolean;
}

export function SinglePostBoxTag({ featured = false, title, href, customLink, nofollow = false }: SinglePostBoxTagProps): JSX.Element {
    const dataFeatured = featured ? { 'data-track': 'featured-article-tag' } : { 'data-track': 'article-tag' };
    return (
        <li className={styles.tag}>
            {href ? (
                <a
                    href={href}
                    rel={nofollow ? 'nofollow' : undefined}
                    {...dataFeatured}
                >
                    {title}
                </a>
            ) : customLink ? (
                <CustomLink
                    {...customLink.props}
                    rel={nofollow ? 'nofollow' : undefined}
                    {...dataFeatured}
                >
                    {title}
                </CustomLink>
            ) : (
                title
            )}
        </li>
    );
}

export interface SinglePostBoxProps {
    customLink: JSX.Element;
    title: string | JSX.Element;
    author?: string;
    date?: string;
    readTime?: string;
    image?: string;
    authorImage?: string;
    tags?: JSX.Element;
    type?: 'featured' | 'long' | 'normal';
    priority?: 'high';
    postId: number;
    lazy?: 'lazy';
}

const ResizedPicture = ({ url, sizes, priority, lazy }: { url: string; sizes: string; priority?: 'high'; lazy?: 'lazy' }) => {
    const srcSet = `${publicRuntimeConfig.resizer}1160x/${url} 1160w, ${publicRuntimeConfig.resizer}580x/${url} 580w, ${publicRuntimeConfig.resizer}280x/${url} 280w, ${publicRuntimeConfig.resizer}120x/${url} 120w`;
    return (
        <>
            {priority === 'high' && (
                <Head>
                    <link
                        rel="preload"
                        href={url}
                        as="image"
                        imageSrcSet={srcSet}
                        imageSizes={sizes}
                        fetchpriority="high" // eslint-disable-line
                    />
                </Head>
            )}
            <picture>
                <source
                    srcSet={srcSet}
                    sizes={sizes}
                />
                <img
                    src={url}
                    alt=""
                    decoding="async"
                    fetchpriority={priority === 'high' ? 'high' : undefined} // eslint-disable-line
                    loading={lazy === 'lazy' ? 'lazy' : undefined} // eslint-disable-line
                />
            </picture>
        </>
    );
};

export default function SinglePostBox({ type = 'normal', title, date, author, readTime, image, authorImage, customLink, tags, priority, lazy, postId }: SinglePostBoxProps): JSX.Element {
    const id = useId();

    const dataFeatured = type === 'featured' ? { 'data-featured': true } : {};
    const picture = image ? (
        <figure>
            {type === 'featured' ? (
                <ResizedPicture
                    url={image}
                    priority={priority}
                    sizes="(min-width: 767px) 580px, 100vw"
                />
            ) : type === 'normal' ? (
                <ResizedPicture
                    url={image}
                    priority={priority}
                    lazy={lazy}
                    sizes="(min-width: 767px) 280px, 100vw"
                />
            ) : (
                <ResizedPicture
                    url={image}
                    priority={priority}
                    lazy={lazy}
                    sizes="120px"
                />
            )}
        </figure>
    ) : (
        <></>
    );
    return (
        <div
            {...dataFeatured}
            className={clsx(styles.single, styles[type])}
            data-id={postId}
        >
            <article
                data-test="article"
                aria-labelledby={id}
            >
                <div className={styles.top}>
                    {customLink ? (
                        <CustomLink
                            {...customLink.props}
                            className={styles.figure}
                        >
                            {picture}
                        </CustomLink>
                    ) : (
                        <div className={styles.figure}>{picture}</div>
                    )}
                    <header
                        className={styles.header}
                        id={id}
                    >
                        {tags ? <ul className={styles.tags}>{tags}</ul> : null}
                        {customLink ? (
                            <CustomLink
                                {...customLink.props}
                                className={styles.title}
                            >
                                <h2>{title}</h2>
                            </CustomLink>
                        ) : (
                            <h2 className={styles.title}>{title}</h2>
                        )}
                    </header>
                </div>
                <SinglePostDetails
                    type={type}
                    date={date}
                    author={author}
                    readTime={readTime}
                    authorImage={authorImage}
                />
            </article>
        </div>
    );
}
