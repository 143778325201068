import { Children } from 'react';

import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';

import styles from './Breadcrumbs.module.css';
import SVGHome from './home.svg';

export default function Breadcrumbs({ children }: React.PropsWithChildren): JSX.Element {
    const { t } = useTranslation('pages/blog/navigation');
    const arrayChildren = Children.toArray(children);

    // https://www.w3.org/WAI/ARIA/apg/patterns/breadcrumb/examples/breadcrumb/
    return (
        <nav
            className={styles.breadcrumbs}
            aria-label={t('aria:breadcrumbsNavigationLabel')}
        >
            <ol>
                <li>
                    <CustomLink
                        className={styles.link}
                        href={{ pathname: '/' }}
                    >
                        <img
                            src={SVGHome.src}
                            width={SVGHome.width}
                            height={SVGHome.height}
                            alt={t('altHome')}
                            className={styles.icon}
                        />
                    </CustomLink>
                </li>
                {arrayChildren.map((child, i) => (
                    <li key={i}>{child}</li>
                ))}
            </ol>
        </nav>
    );
}
