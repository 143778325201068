import React, { useCallback, useEffect, useId, useRef, useState } from 'react';

import { useRouter } from 'next/router';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteBlog } from '@static_components/laravelLinks';
import clsx from 'clsx';

import styles from './Categories.module.css';
import { ReactComponent as SVGClose } from './close.svg';

interface CategoriesProps {
    tags: Array<{
        name: string;
        href: string;
    }>;
}

function Categories({ tags }: CategoriesProps): JSX.Element {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const toggleRef = useRef<HTMLButtonElement>(null);
    const [isOpened, setIsOpened] = useState(false);
    const firstRender = useRef(true);
    const id = useId();

    const { t } = useTranslation('pages/blog/navigation');
    const { locale, query } = useRouter();

    const handleButtonClick = () => {
        setIsOpened(true);
    };
    const handleCloseButtonClick = () => {
        setIsOpened(false);
    };

    const backdropClick = useCallback((e: MouseEvent) => {
        if (!e.target) {
            return;
        }
        if (!dialogRef.current || !dialogRef.current.contains(e.target as Node)) {
            if (dialogRef.current?.getAttribute('open') !== null) {
                setIsOpened(false);
            }
        }
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (!dialogRef.current || !toggleRef.current) {
            return;
        }
        if (isOpened) {
            document.addEventListener('click', backdropClick);
            // const positionTop = toggleRef.current?.getBoundingClientRect()?.y + toggleRef.current?.offsetHeight;
            const positionTop = toggleRef.current.getBoundingClientRect().y + toggleRef.current.offsetHeight + 10;
            dialogRef.current.style.setProperty('--margin-top-start', `${positionTop}px`);
            setTimeout(() => {
                dialogRef.current?.showModal();
                dialogRef.current?.classList.add(styles.beforeShow);
                window.requestAnimationFrame(() => {
                    window.requestAnimationFrame(() => {
                        dialogRef.current?.classList.remove(styles.beforeShow);
                    });
                });
            }, 0);
        } else {
            document.removeEventListener('click', backdropClick);
            setTimeout(() => {
                dialogRef.current?.classList.add(styles.beforeHide);
                const onTransitionEnd = () => {
                    dialogRef.current?.close();
                    dialogRef.current?.classList.remove(styles.beforeHide);
                    dialogRef.current?.removeEventListener('transitionend', onTransitionEnd);
                };
                dialogRef.current?.addEventListener('transitionend', onTransitionEnd);
            }, 0);
        }

        return () => {
            document.removeEventListener('click', backdropClick);
        };
    }, [isOpened, backdropClick]);
    return (
        <nav
            className={clsx(styles.categories, isOpened ? styles.opened : '')}
            aria-labelledby={id}
        >
            <button
                ref={toggleRef}
                className={styles.toggle}
                onClick={handleButtonClick}
            >
                {t('categories')}
            </button>
            <dialog
                ref={dialogRef}
                onCancel={(e) => {
                    e.preventDefault();
                    setIsOpened(false);
                }}
            >
                <header
                    className={styles.header}
                    id={id}
                >
                    {t('categories')}
                </header>
                <ul className={styles.list}>
                    {tags.map((tag, i) => {
                        return (
                            <li key={i}>
                                <a
                                    href={laravelRouteBlog(locale) + `/topic/${tag.href}`}
                                    className={clsx(styles.tag, tag.href === query?.slug ? styles.active : '')}
                                    data-test="featured-article-tag"
                                    data-track="featured-article-tag"
                                    aria-current={tag.href === query?.slug ? true : false}
                                >
                                    {tag.name}
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <button
                    aria-label={t('aria:dialogCloseLabel')}
                    className={styles.close}
                    onClick={handleCloseButtonClick}
                >
                    <SVGClose
                        width={10}
                        height={10}
                    />
                </button>
            </dialog>
        </nav>
    );
}
export default Categories;
